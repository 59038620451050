.forgotPassLink {
  text-decoration: underline;
}

.formLinkBox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
}

.actionBtn {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: var(--not-changine-white);
  width: 100%;
  border-radius: 0 0 4px 4px;
}

:global(.modalLogin .custom-control-label) {
  color: var(--not-changine-black);
}
