.container {
  padding: 40px 0 180px 0;
  overflow: hidden;
  font-family: 'retro', serif;
}

.container p,
.container button,
.container div {
  font-family: 'retro', serif;
}
.container :global(.dropdown) button {
  background: var(--button-primary-enabled);
  border-color: var(--button-primary-enabled);
}

.container table {
  color: var(--text-color);
}

.container table tr:hover {
  color: var(--button-primary-enabled);
  cursor: pointer;
}

.container :global(.retroColorChangeClass) {
  border: 1px solid var(--blackColor);
}

@media (max-width: 991px) {
  .container {
    padding: 40px 0 40px 0;
  }

  .scoreContainer {
    display: flex;
    justify-content: center;
    margin: 25px 0;
  }

  .scoreContainer :global(.jumbotron) {
    margin: 0;
  }
}

@media (max-width: 550px) {
  .container :global(table) {
    min-width: 600px;
  }
}
