:global(.rain) {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  pointer-events: none;
  overflow: hidden;
}

:global(.rain.back-row) {
  display: none;
  z-index: 1;
  bottom: 60px;
  opacity: 0.5;
}

:global(.drop) {
  position: absolute;
  bottom: 100%;
  width: 15px;
  height: 120px;
  pointer-events: none;
  animation: drop 0.5s linear infinite;
}

@keyframes drop {
  0% {
    transform: translateY(0vh);
  }
  75% {
    transform: translateY(90vh);
  }
  100% {
    transform: translateY(90vh);
  }
}

:global(.stem) {
  width: 1px;
  height: 60%;
  margin-left: 7px;
  background: linear-gradient(to bottom, #a8a8a8, rgba(255, 255, 255, 0.25));
  animation: stem 0.5s linear infinite;
}

@keyframes :global(stem) {
  0% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  75% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

:global(.splat) {
  width: 15px;
  height: 10px;
  border-top: 2px dotted rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  opacity: 1;
  transform: scale(0);
  animation: splat 0.5s linear infinite;
  display: none;
}

:global(body.splat-toggle .splat) {
  display: block;
}

@keyframes :global(splat) {
  0% {
    opacity: 1;
    transform: scale(0);
  }
  80% {
    opacity: 1;
    transform: scale(0);
  }
  90% {
    opacity: 0.5;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
