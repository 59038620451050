.reactSwitchCheckbox {
  display: none;
}

.reactSwitchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /*TODO return this for lady-mode*/
  /*width: 105px;*/
  width: 70px;
  height: 35px;
  background: grey;
  border-radius: 48px;
  position: relative;
  background: linear-gradient(#39598a, #79d7ed);
  transition: background-color 0.2s;
}

.reactSwitchLabel .reactSwitchButton {
  content: '';
  position: absolute;
  z-index: 50;
  top: 5px;
  /*TODO return this for lady-mode*/
  /*left: calc(50% - 13px); */
  left: calc(25% - 13px);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: var(--white);
  transition: 0.2s;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.reactSwitchLabel button {
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
}

.reactSwitchLabel img {
  width: 20px;
}

/*switcher span position*/
:global(body.dark-theme) .reactSwitchLabel .reactSwitchButton {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

:global(body.lady-theme) .reactSwitchLabel .reactSwitchButton {
  left: calc(0% + 5px);
}

/*switcher  backgrounds */
:global(body.dark-theme) .reactSwitchLabel {
  background: linear-gradient(#091236, #1e215d);
}

:global(body.lady-theme) .reactSwitchLabel {
  background: linear-gradient(#ffadfa, rgb(190, 143, 244));
}

@media (max-width: 780px) {
  .reactSwitchLabel .reactSwitchButton {
    transition: 0.4s;
  }
}
