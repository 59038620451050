@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css');
@import url('https://fonts.googleapis.com/css?family=Ubuntu:400,500&display=swap&subset=cyrillic,cyrillic-ext,latin-ext');
@import 'styles/helpers/colors.css';
@import 'styles/base/icons.css';
@import 'styles/base/typography.css';
@import '~video-react/dist/video-react.css';

/*for titles tcs*/
@font-face {
  font-family: 'museo-slab';
  src: url('styles/fonts/MuseoSlab/MuseoSlab-700.eot');
  src: url('styles/fonts/MuseoSlab/MuseoSlab-700.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/MuseoSlab/MuseoSlab-700.woff') format('woff'),
    url('styles/fonts/MuseoSlab/MuseoSlab-700.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'museo-slab';
  src: url('styles/fonts/MuseoSlab/MuseoSlab-300.eot');
  src: url('styles/fonts/MuseoSlab/MuseoSlab-300.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/MuseoSlab/MuseoSlab-300.woff') format('woff'),
    url('styles/fonts/MuseoSlab/MuseoSlab-300.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/*fot text*/
@font-face {
  font-family: 'hind-tcs';
  src: url('styles/fonts/MuseoSans/MuseoSansCyrl-700.eot');
  src: url('styles/fonts/MuseoSans/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/MuseoSans/MuseoSansCyrl-700.woff') format('woff'),
    url('styles/fonts/MuseoSans/MuseoSansCyrl-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'hind-tcs';
  src: url('styles/fonts/MuseoSans/MuseoSansCyrl-300.eot');
  src: url('styles/fonts/MuseoSans/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
    url('styles/fonts/MuseoSans/MuseoSansCyrl-300.woff') format('woff'),
    url('styles/fonts/MuseoSans/MuseoSansCyrl-300.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'hind';
  src: url('styles/fonts/Hind/hind-medium-webfont.eot');
  src: url('styles/fonts/Hind/hind-medium-webfont.woff2') format('woff2'),
    url('styles/fonts/Hind/hind-medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'hind';
  src: url('styles/fonts/Hind/hind-regular-webfont.eot');
  src: url('styles/fonts/Hind/hind-regular-webfont.woff2') format('woff2'),
    url('styles/fonts/Hind/hind-regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'retro';
  src: url('styles/fonts/Game/retro-game.ttf');
  font-weight: 400;
  font-style: normal;
}

:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

/* Should be removed to disable scroll top when modal open */
/* html,
body {
  overflow-x: hidden;
} */

html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Ubuntu', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--body-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
}

.form-control:focus {
  box-shadow: none;
}

.form-control {
  color: var(--placeholder-color);
  padding: 8px 12px;
  height: 44px;
}

.form-control::placeholder {
  color: var(--placeholder-color);
  opacity: 1;
}

.container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.responsive-img {
  max-width: 100%;
  height: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul,
ol {
  list-style: none;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--theme-primary);
  transition: all ease 0.3s;
}

a:hover {
  cursor: pointer;
  text-decoration: none;
  color: var(--button-default-focused);
}

*,
:focus,
a,
a:focus,
button,
button:focus {
  outline: none;
}

video {
  display: inline-block;
}

#root {
  /*max-width: 1440px;*/
  margin: 0 auto;
  background-color: var(--whiteColor);
  transition: all 0.5s ease;
}

/*********************************BUTTON*********************************/

.btnLabel {
  color: var(--theme-primary);
  text-decoration: none;
  transition: all ease-in 0.3s;
  background-color: transparent;
}

.btnLabel:hover,
.btnLabel:focus,
.btnLabel.hover,
.btnLabel.focus {
  background-color: var(--button-default-focused-10);
  border-color: var(--button-default-focused-10);
  color: var(--theme-primary);
}

.btn {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  letter-spacing: 0.02px;
  padding: 6px 24px;
  border-radius: 4px;
  transition: all ease 0.3s;
  min-width: 104px;
  border-width: 2px;
}

.btn:first-letter {
  text-transform: uppercase;
}

.btn.focus,
.btn:focus,
.btn.active,
.btn:active {
  outline: 0;
  box-shadow: none;
}

.btn-primary {
  background-color: var(--theme-accent);
  border-color: var(--theme-accent);
  color: var(--not-changine-black);
}

.btn-primary:focus,
.btn-primary.focus,
.btn-primary:hover {
  background-color: var(--button-primary-focused);
  border-color: var(--button-primary-focused);
  color: var(--not-changine-black);
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: var(--button-primary-disabled);
  border-color: var(--button-primary-disabled);
  color: rgba(var(--blackColor), 0.5);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: var(--button-primary-pressed);
  border-color: var(--button-primary-pressed);
  color: var(--blackColor);
}

.btn-secondary {
  background-color: var(--theme-primary);
  border-color: var(--theme-primary);
  color: var(--not-changine-white);
}

.btn-secondary:focus,
.btn-secondary.focus,
.btn-secondary:hover,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):active:focus {
  background-color: var(--button-default-focused);
  border-color: var(--button-default-focused);
  color: var(--not-changine-white);
  box-shadow: none;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: var(--button-default-disabled);
  border-color: var(--button-default-disabled);
  color: var(--not-changine-white);
}

.btn-outline-primary {
  border-color: var(--theme-accent);
  color: var(--blackColor);
}

.btn-outline-primary:focus,
.btn-outline-primary.focus,
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: var(--button-primary-focused-10);
  border-color: var(--button-primary-focused);
  color: var(--blackColor);
  box-shadow: none;
}

.btn-outline-secondary {
  border-color: var(--theme-primary);
  color: var(--neutral-dark-blue);
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus,
.btn-outline-secondary:hover,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: var(--button-outline-focused);
  border-color: var(--theme-primary);
  color: var(--neutral-dark-blue);
  box-shadow: none;
}

.dark-theme .btn-outline-secondary:focus,
.dark-theme .btn-outline-secondary.focus,
.dark-theme .btn-outline-secondary:hover,
.dark-theme .btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: var(--theme-primary);
  color: var(--neutral-dark-blue);
}

.linkBtn {
  display: flex;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 17px;
  color: var(--theme-primary);
}

.linkBtn > .icon-arrow {
  color: currentColor;
  font-size: 14px;
  margin-left: 5px;
  margin-top: 2px;
}

.closeBtn {
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  background-color: var(--modal-btn-color);
  padding: 0;
  min-width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1051;
}

.closeBtn .icon-close {
  color: var(--theme-primary);
}

.closeBtn:focus,
.closeBtn:hover,
.closeBtn:visited {
  background-color: transparent;
}

.closeBtnWhite,
.closeBtnWhite:focus,
.closeBtnWhite:hover,
.closeBtnWhite:visited {
  color: var(--whiteColor);
}

.closeBtnBlue,
.closeBtnBlue:focus,
.closeBtnBlue:hover,
.closeBtnBlue:visited {
  color: var(--theme-primary);
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
  color: var(--theme-primary);
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary.dropdown-toggle {
  color: var(--theme-primary);
}

/*********************************BUTTON END*********************************/

/**********************************MODAL***************************************/

.modal-dialog.modal-sm {
  max-width: 346px;
}

.modal-dialog.modal-lg {
  max-width: 740px;
}

.modal-content {
  border-radius: 6px;
}

.modal-header button.close {
  position: absolute;
  right: 8px;
  top: 8px;
  width: 32px;
  height: 32px;
  margin: 0;
  opacity: 1;
  font-weight: normal;
  cursor: pointer;
  text-shadow: none;
}

button.close > span {
  opacity: 0;
}

.modal-header button.close:before {
  content: '\e909';
  position: absolute;
  font-family: 'icomoon';
  font-size: 32px;
  color: var(--theme-primary);
  left: 0;
  top: 0;
}

.modal-open {
  height: 100vh;
}

.modal.show .modal-dialog {
  width: 100%;
  margin: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.modal.fade .modal-dialog {
  transition: all 0.3s ease;
}

.modalLogin .modal-header {
  padding: 20px;
}

.modalLogin .modal-body,
.modalLogout .modal-body,
.modalLogout .modal-header {
  padding: 0 20px;
}

.modal-body .btn-link {
  display: inline-block;
  margin: 0 auto;
}

.modal-header {
  border-bottom: none;
}

.modal-title {
  color: var(--not-changine-black);
  margin-top: 18px;
}

.modal-content {
  border: none;
}

.modal-footer {
  padding: 0;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  border-top: none;
}

.modal-sm .modal-footer .btn {
  width: 100%;
  border-radius: 0 0 6px 6px;
  text-align: center;
}

.modal-sm .modal-footer .btn:nth-last-child(2) {
  display: inline-block;
  text-align: center;
}

.modal-footer > :not(:first-child) {
  margin-left: 0;
}

.modal-footer > :not(:last-child) {
  margin-right: 0;
}

.modal-footer > .btn-link {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: var(--modal-footer-btn-color);
}

.modal-backdrop.show {
  opacity: 0.9;
}

.modalQuestion .modal-content {
  padding: 34px 50px 24px;
}

.modalQuestion .modal-content {
  background-color: var(--whiteColor);
}

.modalQuestion :global(.modal-footer) {
  justify-content: flex-start;
}

.modalQuestion .modal-body,
.modalQuestion .modal-header {
  padding: 0;
}

.modalQuestion .closeBtn {
  left: auto;
  right: 14px;
  top: 14px;
}

.askContentTitle {
  color: var(--neutral-dark-blue);
}

.askContentText {
  color: var(--neutral-gray-blue);
}

.navbar-toggler {
  z-index: 1039;
}

@media (max-width: 991px) {
  .navbar.navbar-expand-lg > .container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .navbar {
    padding: 0;
  }
}

.collapsing {
  position: relative;
  left: 0;
  top: 0;
  height: 0;
  overflow: hidden;
  transition: all 0.35s ease;
}

.navbar-collapse.collapse {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: var(--whiteColor);
  transition: all 0.5s ease;
}

.navbar-collapse.collapse.show {
  position: relative;
  left: 0;
  top: 0;
  background-color: var(--whiteColor);
  transition-duration: 0.55s;
}

.navbar-light .navbar-toggler {
  border: none;
  padding: 0;
  margin-left: 5px;
}

.navbar-light .navbar-nav .nav-link {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  transition: 0.3s;
  color: var(--neutral-dark-blue);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link.active {
  color: var(--button-default-pressed);
  font-weight: 500;
}

.pageSubtitle {
  color: var(--neutral-dark-blue);
  margin-bottom: 32px;
}

.pageText {
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.pageTextMd {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
}

.dropdown-menu {
  border: none;
  padding: 0;
}

.dropdown-item {
  padding: 0 30px 0 0;
}

.imgContainer img {
  width: auto;
  height: 100%;
}

.demoVersion {
  position: absolute;
  top: -10px;
  right: 35%;
  z-index: 100;
  background: url('../src/img/demo-label.svg') no-repeat 50% 50%;
  background-size: contain;
  height: 100px;
  width: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.demoText {
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: var(--not-changine-black);
  max-width: 100%;
  text-align: center;
}

.card-title {
  font-size: 36px;
  line-height: 44px;
  color: var(--neutral-dark-blue);
}

.rsbtn {
  display: none !important;
}

.carousel-item .video-react .video-react-big-play-button.video-react-big-play-button-center {
  top: 50%;
  left: 50%;
  height: 1.5em;
  border: none;
  border-radius: 50%;
  width: 1.5em;
  margin-top: 0;
  margin-left: 0;
  transform: translate(-50%, -50%);
  transition: all ease 0.3s;
}

.carousel-item .video-react:hover .video-react-big-play-button.video-react-big-play-button-center {
  background-color: rgba(43, 51, 63, 0.7);
}

.carousel-item .video-react .video-react-big-play-button.video-react-big-play-button-center:hover {
  background-color: #2b333f;
}

/*************************************MEDIA*****************************************/

@media (min-width: 1200px) {
  .container {
    max-width: 1240px;
  }
}

@media (max-width: 1199px) {
  .pageSubtitle {
    margin-bottom: 16px;
  }

  .rulesListItemBg {
    width: 60px;
    height: 60px;
  }

  .pageSubtitle {
    text-align: center;
  }

  .promoItem:last-child {
    max-width: 45%;
  }

  .demoVersion {
    right: 20%;
    width: 60px;
    height: 50px;
  }

  .demoText {
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
  }
}

@media (max-width: 991px) {
  #root {
    position: relative;
  }

  .navbar {
    position: static;
  }

  .navbar-light .navbar-nav .nav-link {
    font-family: 'Ubuntu', sans-serif;
    font-size: 18px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .navbar-light .navbar-nav .nav-link[data-rb-event-key='#mycockpit'] {
    padding: 5px 5px 0 5px;
  }

  .demoVersion {
    display: none;
  }

  .navbar-collapse {
    padding: 0 65px;
  }

  .modalQuestion .modal-content {
    padding: 40px 24px 14px 24px;
  }
}

@media (max-width: 767px) {
  .pageSubtitle {
    text-align: left;
  }
}

@media screen and (orientation: landscape) and (max-device-width: 991px) {
  html {
    overflow-y: auto;
  }

  .navbar-collapse.collapse.show {
    overflow-y: auto;
    max-height: calc(100vh - 40px);
    height: 100%;
  }

  .modal-content {
    max-height: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .modal-open .modal {
    overflow-y: hidden;
  }

  .modal.show .modal-dialog {
    height: 100%;
  }

  .modalLogin.modal.show .modal-dialog {
    height: unset;
  }

  .modal-content .modal-footer {
    overflow: visible;
  }
}

.notificationSWContainer {
  max-width: 1440px;
  position: fixed;
  z-index: 11;
  bottom: 25px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}

.notificationSWContainer button {
  margin-left: 15px;
}

@media (max-width: 450px) {
  .notificationSWContainer {
    font-size: 13px;
  }
}

#SWNotification {
  display: none;
}

body .retroFont {
  font-family: 'retro', serif;
  transition: all 0.5s ease;
}

.retroColorChangeClass {
  animation: retroColorChange 20s infinite;
}

.retroTextChangeClass {
  animation: retroTextColorChange 20s infinite;
}

@keyframes retroColorChange {
  0% {
    border-color: blue;
  }
  10% {
    border-color: #8e44ad;
  }
  20% {
    border-color: #1abc9c;
  }
  30% {
    border-color: #d35400;
  }
  40% {
    border-color: blue;
  }
  50% {
    border-color: #34495e;
  }
  60% {
    border-color: blue;
  }
  70% {
    border-color: #2980b9;
  }
  80% {
    border-color: #f1c40f;
  }
  90% {
    border-color: #2980b9;
  }
  100% {
    border-color: pink;
  }
}

@keyframes retroTextColorChange {
  0% {
    color: blue;
  }
  10% {
    color: #8e44ad;
  }
  20% {
    color: #1abc9c;
  }
  30% {
    color: #d35400;
  }
  40% {
    color: blue;
  }
  50% {
    color: #34495e;
  }
  60% {
    color: blue;
  }
  70% {
    color: #2980b9;
  }
  80% {
    color: #f1c40f;
  }
  90% {
    color: #2980b9;
  }
  100% {
    color: pink;
  }
}
