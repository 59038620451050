.btnGroup {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

:global(.dark-theme) .btnGroup :global(.btn):hover {
  background-color: var(--theme-accent);
  color: var(--not-changine-black);
}

.btnGroup :global(.btn):first-child,
.btnGroup :global(.btn):nth-child(2) {
  margin-right: 20px;
}

@media (max-width: 1199px) {
  .btnGroup {
    justify-content: center;
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .btnGroup {
    flex-wrap: wrap;
  }

  .btnGroup > :global(.btn) {
    margin-top: 10px;
    max-width: 346px;
    width: 100%;
  }

  .btnGroup > :global(.btn):first-child,
  .btnGroup :global(.btn):nth-child(2) {
    margin-right: 0;
  }
}
