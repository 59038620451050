.examBgContainer {
  background: url('../../../src/img/examBg.svg') no-repeat 50% 50%;
  background-size: cover;
}

.pcInstructor {
  max-width: calc(50% - 10px);
  width: 100%;
}

.pcInstructor .instructorInfo {
  margin-right: 0;
}

@media (max-width: 1450px) {
  .pcInstructor > div {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .pcInstructor {
    display: none;
  }

  .examBgContainer {
    background: none;
    margin-top: 0;
  }
}
