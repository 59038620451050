.registration {
  padding: 40px 0;
}

.registration :global(.linkBtn) {
  margin-bottom: 20px;
  margin-left: 20px;
}

.registration :global(.linkBtn .icon-arrow) {
  transform: rotate(180deg);
  margin-right: 5px;
}

.registration :global(.btn-secondary),
.registration :global(.btn-outline-danger) {
  display: block;
  margin-left: auto;
  margin-top: 20px;
}

.formRegistration {
  margin-top: 24px;
}

.formTitle {
  font-family: 'Ubuntu', sans-serif;
  font-size: 28px;
  line-height: 42px;
  font-weight: 500;
  color: var(--neutral-dark-blue);
  margin-left: 20px;
}

.planInfo {
  width: 100%;
  border-radius: 10px;
  background-color: var(--neutral-light-gray);
  padding: 24px 32px;
}

.planInfoBox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.planInfoBox > .planInfoItem {
  max-width: calc(50% - 5px);
  width: 100%;
  margin-bottom: 8px;
}

.itemLabel {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue-50);
  margin-bottom: 4px;
}

.itemValue {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
}

.registration :global(.form-group) {
  margin-bottom: 22px;
}

.affiliatePrice {
  position: relative;
  display: inline;
}

.affiliatePrice::after {
  border-bottom: 0.13em solid red;
  content: '';
  margin-top: calc(0.2em / 2 * -1);
  position: absolute;
  top: 50%;
  width: 130%;
  left: -9px;
}

.registration :global .form-control {
  height: 44px;
  border: 1px solid var(--neutral-gray);
  background-color: var(--whiteColor);
  color: var(--text-color);
}

:global(.dark-theme) .registration :global(.form-group) input::placeholder {
  color: var(--dark-mode-input-placeholder);
}

.paymentList :global(.list-group-item:not(:last-child)) {
  margin-bottom: 8px;
}

.paymentList :global(.list-group-item) {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: var(--neutral-light-gray);
  border: 1px solid var(--additional-gray-blue);
  border-radius: 10px;
  font-family: 'Hind', sans-serif;
  font-size: 16px;
  line-height: 21px;
  color: var(--neutral-gray-blue);
  cursor: pointer;
  transition: all ease 0.3s;
  padding: 12px 13px;
}

.paymentList :global(.list-group-item:hover),
.paymentList :global(.list-group-item.active) {
  background-color: var(--whiteColor);
  border-color: var(--theme-primary);
  border-width: 3px;
}

.paymentList :global(.p3) {
  color: var(--neutral-gray-blue);
}

:global(.custom-control-label::before) {
  position: absolute;
  top: 0;
  left: -24px;
  display: block;
  width: 24px;
  height: 24px;
  content: '';
  background-color: var(--not-changine-white);
  border: 1px solid var(--neutral-gray);
  transition: all ease 0.3s;
}

:global .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

:global(.custom-control-input) {
  position: absolute;
  opacity: 0;
  z-index: 2;
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
}

:global .custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--not-changine-black);
  background-color: var(--not-changine-white);
}

:global .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
  content: '\e902';
  color: var(--theme-primary);
  font-family: 'icomoon';
  width: 24px;
  height: 24px;
  top: 0;
  left: -24px;
  font-size: 24px;
}

:global .custom-control-input.is-valid:checked ~ .custom-control-label::before,
:global .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
  background-color: var(--whiteColor);
}

:global .custom-control-input.is-valid:checked ~ .custom-control-label::after,
:global .was-validated .custom-control-input:valid:checked ~ .custom-control-label::after {
  color: var(--functional-success);
}

:global .custom-control-input.is-valid ~ .custom-control-label,
:global .was-validated .custom-control-input:valid ~ .custom-control-label {
  color: var(--neutral-gray-blue);
}

:global .custom-checkbox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
}

:global .custom-control-label {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--text-color);
  padding-left: 20px;
}

.planInfoTopOffset {
  margin-top: 20px;
}

.planInfoTopOffsetMobile {
  display: none;
}

.voucherMobile {
  display: none;
}

@media (max-width: 991px) {
  .affiliateInfo {
    max-width: unset;
  }

  .planInfo {
    margin-bottom: 20px;
  }

  .registration :global(.linkBtn),
  .formTitle {
    margin-left: 0;
  }

  .formRegistration .planInfo {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
  }

  .formRegistration .planInfo .itemLabel {
    /*display: none;*/
  }

  .formRegistration .planInfo.planInfoTopOffsetMobile .itemLabel {
    display: block;
  }

  .formRegistration .planInfo .itemValue,
  .formRegistration .planInfoBox .planInfoItem {
    margin-bottom: 0;
  }

  .formRegistration .planInfoBox {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .formRegistration .planInfo > .planInfoItem > .itemValue,
  .formRegistration .planInfoBox .planInfoItem .itemValue {
    font-family: 'Ubuntu', sans-serif;
    font-weight: bold;
    font-size: 14px;
    line-height: 21px;
  }

  .formRegistration .planInfo > .planInfoItem > .itemValue,
  .formRegistration .planInfoBox .planInfoItem:last-child .itemValue {
    text-transform: uppercase;
    color: var(--neutral-dark-blue);
  }

  .formRegistration .planInfo > .planInfoItem > .itemValue {
    margin-right: 70px;
  }

  .formRegistration .voucherPlan > .planInfoItem > .itemValue {
    margin-right: 5px;
  }

  .formRegistration .voucherPlan > .planInfoItem {
    width: 100%;
  }

  .formRegistration .planInfoBox .planInfoItem:first-child .itemValue {
    color: var(--neutral-dark-blue-50);
  }

  .planInfo.planInfoTopOffset {
    /*display: none;*/
  }

  .planInfo.topBlock .planInfoItem {
    display: none;
  }

  .planInfo.topBlock .voucherMobile .planInfoItem {
    display: flex;
  }

  .planInfo .planInfoBox .planInfoItem {
    display: block;
  }

  .voucherMobile {
    display: flex;
    width: 100%;
    height: 47px;
  }

  .voucherMobile .planInfoItem {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .voucherMobile .planInfoItem .itemLabel,
  .planInfo .planInfoBox .itemLabel {
    display: block;
  }
  .formRegistration .voucherPlan .planInfoBox {
    max-width: 38%;
  }

  body .planInfoTopOffsetMobile.planInfo {
    display: none;
  }
}

@media (max-width: 767px) {
  .planInfoBox .itemLabel,
  .voucherMobile .itemLabel {
    white-space: nowrap;
  }
  .voucherPlan .voucherMobile .planInfoItem {
    align-items: center;
  }

  .planInfo {
    max-width: none;
  }

  .registration :global(.btn-secondary) {
    margin-left: 0;
    width: 100%;
  }

  .registration :global(.btn-outline-danger) {
    margin-left: 0;
    width: 100%;
    margin-bottom: 15px;
  }

  .affiliateInfo.planInfo > .planInfoItem > .itemValue {
    margin: 0 10px;
  }
}

@media (max-width: 500px) {
  .formRegistration .planInfo {
    flex-flow: column nowrap;
    justify-content: center;
    align-content: center;
  }

  .formRegistration .planInfo > .planInfoItem > .itemValue {
    margin-right: 0;
    text-align: center;
  }

  .formRegistration .planInfo > .planInfoItem > .itemLabel {
    margin: 5px 0;
    text-align: center;
  }

  .formRegistration .planInfoBox,
  .formRegistration .voucherPlan .planInfoBox {
    max-width: 100%;
    width: 100%;
    margin-top: 20px;
  }

  .formRegistration .planInfoBox .planInfoItem {
    text-align: center;
  }

  .voucherMobile .itemValue {
    font-size: 16px;
  }

  .voucherMobile .planInfoItem,
  .voucherPlan,
  .voucherMobile .planInfoItem {
    align-items: center;
  }
}

@media (max-width: 450px) {
  .affiliateInfo.planInfo > .planInfoItem > .itemValue {
    font-size: 13px;
  }

  .affiliateInfo.planInfo {
    padding: 5px;
  }

  .formRegistration .voucherPlan {
    padding: 20px 5px;
  }
}
