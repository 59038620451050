.tableContainer :global(th),
.tableContainer :global(td) {
  text-align: center;
}

.tableContainer {
  color: var(--text-color);
}

.loaderWrapper {
  width: 100%;
  min-width: 100%;
  position: absolute;
  min-height: 180px;
}

.noResultContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
}

.noResultContainer p {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  white-space: nowrap;
  font-size: 14px;
  line-height: 30px;
  text-transform: uppercase;
  color: var(--neutral-dark-blue);
}
