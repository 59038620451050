.cardItem {
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 20px var(--box-shadow);
  border-radius: 6px;
  width: 100%;
  padding: 20px 20px 0 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  min-width: 300px;
  color: var(--text-color);
}

:global(.dark-theme) .cardItem {
  background-color: var(--neutral-light-gray-no-opacity);
}
