.paymentModal :global .modal-dialog {
  max-width: 350px;
}

.paymentModal :global .modal-title {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 0.1px;
  color: var(--not-changine-black);
  margin-bottom: 12px;
  margin-top: 0;
}

.modalText {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--not-changine-black);
  margin-bottom: 12px;
}

.paymentModal :global .modal-header,
.paymentModal :global .modal-body {
  padding: 0;
}

.paymentModal :global .modal-content {
  padding: 20px 20px 10px;
}

.paymentModal :global .modal-footer > button {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--modal-footer-btn-color);
  margin-top: 22px;
}

.paymentModal :global .modal-footer > button:hover {
  text-decoration: none;
}
