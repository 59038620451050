.mobileView {
  display: none;
}

.imageBox {
  width: 100%;
  max-width: calc(50% - 10px);
}

@media (max-width: 1450px) {
  .imageBox > div {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 991px) {
  .theoryPageTopContent {
    padding: 24px 0 40px;
  }

  .mobileView {
    display: block;
  }
}
