.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.container div {
  width: 7px;
  height: 7px;
  border: 1px solid var(--text-color);
  margin: 3px 3px;
  background-color: var(--blackColor);
}
