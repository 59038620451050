.vipPage {
  padding-top: 54px;
  margin-bottom: -57px;
}

.vipPage :global .linkBtn {
  display: none;
}

.vipPageText {
  text-align: left;
}

.vipPageContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 60px;
}

.vipPageSubtitle {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: var(--neutral-dark-blue);
  margin-bottom: 24px;
}

.vipCodeField {
  width: 100%;
}

.vipCodeField :global .form-group {
  margin-bottom: 0;
  margin-right: 20px;
  max-width: 300px;
  width: 100%;
  flex-grow: 1;
}

.vipCodeField :global .form-group input,
.vipCodeField :global .form-group input:focus,
.vipCodeField :global .form-group input:active,
.vipCodeField :global .form-group input:hover {
  background-color: var(--whiteColor);
  color: var(--text-color);
}

:global(.dark-theme) .vipCodeField :global .form-group input::placeholder {
  color: var(--dark-mode-input-placeholder);
}

.formContent button {
  min-width: 0;
  flex-shrink: 0;
}

.vipCodeInput {
  height: 44px;
  border-radius: 6px;
}

.vipItem {
  max-width: calc(50% - 20px);
  width: 100%;
  margin-right: 20px;
}

.vipCodePageText {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  max-width: 300px;
  text-align: left;
}

.formContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 26px;
}

.vipCodeBottomText {
  background-color: var(--lavender-grey);
  padding: 74px 0 102px;
}

@media (max-width: 991px) {
  .vipPage {
    padding-top: 28px;
    margin-bottom: 0;
  }

  .vipPageContent {
    flex-flow: row wrap-reverse;
  }

  .vipItem {
    max-width: 420px;
    margin: 24px auto 0 0;
  }

  .vipPageContent {
    padding-bottom: 0;
  }

  .vipCodeBottomText {
    background-color: var(--whiteColor);
    padding: 0;
  }
}

@media (max-width: 767px) {
  .vipItem {
    max-width: none;
  }

  .formContent {
    flex-flow: row wrap;
  }

  .formContent button {
    width: 100%;
    margin-top: 26px;
  }

  .vipCodeField :global .form-group {
    margin-right: 0;
    max-width: none;
  }
}
