.vipCard {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-end;
  max-width: 500px;
  width: 100%;
  border-radius: 10px;
  background-size: auto 100%;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.08);
  position: relative;
}

.cardLogo {
  width: 100%;
}

.vipCard :global(.linkBtn) {
  position: absolute;
  top: 110px;
  right: 5px;
}

@media (max-width: 1199px) {
  .vipCard :global(.linkBtn) {
    top: 100px;
  }
}

@media (max-width: 991px) {
  .vipCard {
    max-width: 420px;
    margin: 0 auto;
  }

  .vipCard :global(.linkBtn) {
    top: 90px;
  }
}

@media (max-width: 530px) {
  .vipCard :global(.linkBtn) {
    font-size: 12px;
  }
}

@media (max-width: 490px) {
  .vipCard :global(.linkBtn) {
    top: 90px;
  }
}

@media (max-width: 440px) {
  .vipCard :global(.linkBtn) {
    top: 80px;
  }
}

@media (max-width: 410px) {
  .vipCard :global(.linkBtn) {
    top: 70px;
  }
}

@media (max-width: 360px) {
  .vipCard :global(.linkBtn) {
    top: 60px;
  }
}
