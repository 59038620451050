.container {
  padding-top: 40px;
  background-color: var(--blackColor);
  max-height: 400px;
  width: 100%;
  padding-bottom: 40px;
}

.container h5 {
  color: var(--whiteColor);
  margin-right: 100px;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper img {
  width: 420px;
  filter: brightness(var(--filter-bright-color-PNG));
}

@media (max-width: 991px) {
  .container h5 {
    margin-right: 40px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    flex-direction: column;
  }

  .container h5 {
    margin-right: 0;
    text-align: center;
    margin-bottom: 20px;
  }
}

@media (max-width: 500px) {
  .wrapper img {
    width: 80%;
  }
}
