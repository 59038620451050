.container {
  display: flex;
  justify-content: space-around;
}

.container > div {
  padding: 15px;
  width: 50%;
}

.container img {
  margin: 0 auto;
  display: block;
  /*max-width: 80%;*/
  /*width: 100%;*/
  padding-top: 40px;
}

.shopImage {
  width: 645px;
}

.vipImage {
  width: 262px;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

@media (max-width: 991px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .container > div {
    width: 100%;
  }

  .shopImage {
    width: 100%;
  }

  .vipImage {
    width: 100%;
    max-width: 300px;
  }
}
