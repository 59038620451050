.topicList {
  max-width: 400px;
  width: 100%;
  min-width: 0;
  flex-shrink: 0;
  margin-right: 20px;
}

.topicList div {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  margin-right: 20px;
}

.topicList div:not(:last-child) {
  margin-bottom: 12px;
}

.topicList i {
  display: block;
  font-size: 14px;
  color: var(--theme-primary);
}

.topicList button:hover,
.topicList button:focus {
  text-decoration: none;
  color: var(--theme-primary);
}

@media (max-width: 991px) {
  .topicList {
    display: none;
  }
}
