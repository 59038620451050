.resultPageItem {
  width: 100%;
  padding: 40px 0;
}

.resultPageItem h1 {
  color: var(--text-color);
  font-family: 'retro', serif;
  text-align: center;
  font-size: 70px;
  text-transform: uppercase;
  animation-duration: 5s;
}

@media (max-width: 900px) {
  .resultPageItem h1 {
    font-size: 50px;
  }
}

@media (max-width: 600px) {
  .resultPageItem h1 {
    font-size: 40px;
  }
}

@media (max-width: 500px) {
  .resultPageItem h1 {
    font-size: 27px;
  }
}
