.modalGoodBye {
  padding: 0;
}

.modalGoodBye :global(.modal-body) {
  padding: 0;
}

.modalGoodBye :global(.modal-body) {
  padding: 0;
}

.modalGoodBye :global(.modal-footer) {
  padding: 25px 20px;
}

.modalFooterText {
  letter-spacing: 0.1px;
  color: var(--not-changine-black);
}
