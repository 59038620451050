.searchContainer {
  width: 100%;
}

.searchContainer button {
  margin-left: 20px;
}

.searchContainer form {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
}

.searchContainer form input,
.searchContainer form input:focus,
.searchContainer form input:active,
.searchContainer form input:hover {
  background-color: var(--whiteColor);
  color: var(--text-color);
}

:global(.dark-theme) .searchContainer form input::placeholder {
  color: var(--dark-mode-input-placeholder);
}

@media (max-width: 767px) {
  .searchContainer form {
    flex-flow: row wrap;
  }

  .searchContainer button {
    margin-left: 0;
    width: 100%;
    margin-top: 20px;
  }
}
