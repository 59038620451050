.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 3px;
  background-color: var(--white);
  border: 1px solid var(--additional-blue);
  transition: all ease 0.3s;
  color: var(--neutral-dark-blue);
}

.buttonContainer:hover {
  border-color: var(--theme-primary);
  background-color: var(--button-outline-focused);
  width: 42px;
  height: 42px;
}
