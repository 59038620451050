.container {
  max-width: calc(50% - 10px);
  margin: 0 auto;
}

.startButtonContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  /*max-width: 500px;*/
  font-family: 'retro', serif;
}

.startButton {
  font-family: 'retro', serif;
  border: 2px solid #000000;
  animation-duration: 10s;
  color: var(--text-color);
  background-color: var(--whiteColor);
  transition: all 0.5s ease;
}

.startButton:hover {
  animation: retroBackgroundColorChange 5s infinite;
}

.startButtonContainer :global(.btn-outline-success) {
  margin-left: 15px;
}

.startButtonContainer a {
  font-weight: 500;
  margin-top: 30px;
}

.gameCardItem {
  padding: 0 5px;
}

.gameCardInfo {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-radius: 6px;
  padding: 24px 24px 8px;
  /*max-width: 500px;*/
  /*border: 2px solid #000000;*/
  width: 100%;
  margin-bottom: 20px;
  font-family: 'retro', serif;
  transition: all 0.5s ease;
}

.cardLabel {
  font-weight: 500;
  font-size: 18px;
  color: var(--neutral-gray-blue-50);
  margin-bottom: 4px;
}

.cardValue {
  font-weight: 500;
  font-size: 20px;
  padding: 0 0 10px 0;
  color: var(--neutral-gray-blue);
}

@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }

  h1.title {
    text-align: center;
    font-size: 35px;
  }

  p.description {
    font-size: 18px;
    text-align: center;
  }

  .startButtonContainer {
    display: flex;
    justify-content: center;
    max-width: unset;
  }

  .startButtonContainer button {
    width: 100%;
    /*max-width: 350px;*/
  }
}

@media (max-width: 400px) {
  .gameCardInfo {
    justify-content: center;
  }

  .cardLabel {
    text-align: center;
  }

  .cardValue {
    text-align: center;
  }
}

@keyframes retroBackgroundColorChange {
  0% {
    background-color: blue;
  }
  10% {
    background-color: #8e44ad;
  }
  20% {
    background-color: #1abc9c;
  }
  30% {
    background-color: #d35400;
  }
  40% {
    background-color: blue;
  }
  50% {
    background-color: #34495e;
  }
  60% {
    background-color: blue;
  }
  70% {
    background-color: #2980b9;
  }
  80% {
    background-color: #f1c40f;
  }
  90% {
    background-color: #2980b9;
  }
  100% {
    background-color: pink;
  }
}
