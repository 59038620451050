.container {
  display: flex;
  align-items: center;
  gap: 0 20px;
}

:global(.dark-theme) .container :global(#gauge-chart .text-group text) {
  fill: var(--not-changine-white) !important;
}

.container > div {
  min-width: 200px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-height: 200px;
  max-width: 290px;
  max-height: 200px;
  position: relative;
}

.cardMarkedIcon {
  font-size: 105px;
  margin-top: -10px;
  color: var(--button-primary-pressed);
  transition: 3s all;
}

.cardCenterIcon {
  margin: 0 auto;
  transition: 3s all;
}

.resetIcon {
  margin: 0 auto;
}

.resetIcon path {
  stroke: #0080c9;
}

.cardTitleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-bottom: 35px;
}

.cardTitleContainer h6 {
  white-space: nowrap;
  font-size: 18px;
}

.badge {
  position: absolute;
  right: 0;
  top: -20px;
  font-size: 30px;
  padding-right: 15px;
  padding-left: 15px;
  color: var(--white);
}

.badgeWarning {
  background-color: var(--button-primary-pressed);
}

.badgeError {
  background-color: rgb(224, 61, 36);
}

@media (max-width: 1199px) {
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px 20px;
  }
}

@media (max-width: 991px) {
  .container {
    margin-top: 50px;
    gap: 20px 20px;
  }
}

@media (max-width: 767px) {
  .badge {
    font-size: 20px;
  }

  .container {
    gap: 25px 25px;
  }
}
