.root {
  padding: 60px 0 30px;
  color: var(--text-color);
}

.mainImage {
  margin: 30px 0;
}

@media (max-width: 780px) {
  .mainImage {
    height: 185px;
    width: 300px;
  }

  .mainTitle {
    font-size: 38px;
    line-height: 44px;
  }
}
