.questionPage {
  background-color: var(--lavender-grey);
}

.questionLinkBottom {
  display: flex;
  justify-content: space-between;
  margin-bottom: 88px;
  padding-bottom: 30px;
}

.loaderContainer {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1500;
}

.questionBar {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.questionBtnListItem {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--button-icon-enabled);
  width: 48px;
  height: 48px;
  border-radius: 4px;
  cursor: pointer;
  color: var(--theme-primary);
  border: none;
  padding: 0;
}

.emptyMarkedQuestionsContainer {
  padding: 25px 10px;
}

.emptyMarkedQuestionsTitle {
  font-size: 24px;
  font-weight: 700;
}

.emptyMarkedQuestionsDescriptionWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.emptyMarkedQuestionsDescriptionWrapper p {
  margin: 0 15px 0 0;
  font-size: 18px;
}

.doubleCounter {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--whiteColor);
  border-radius: 6px;
  margin: 10px 32px 10px 0;
  border: 1px solid var(--additional-gray-blue);
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: var(--neutral-gray-blue);
}

.headerQuestionBox {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.questionCustomBtn {
  max-width: 350px;
  width: 100%;
  border: 1px solid var(--additional-gray-blue);
  box-sizing: border-box;
  border-radius: 6px;
  background-color: var(--whiteColor);
}

.questionCustomBtn button {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: var(--neutral-dark-blue);
  padding: 8px 16px;
  border: none;
}

.questionCustomBtn button:hover,
.questionCustomBtn button:focus {
  text-decoration: none;
}

.emptyQuestionContainer {
  padding-top: 18px;
}

.questionCustomBtn i {
  font-size: 16px;
  color: var(--theme-primary);
}

.mobileTopic {
  display: none;
}

.theoryMobile {
  display: none;
  margin-bottom: 40px;
  margin-top: -48px;
}

.theoryMobileFooter {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 991px) {
  .questionPage {
    background-color: var(--whiteColor);
  }

  .questionLinkBottom {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .emptyQuestionContainer {
    padding-top: unset;
  }
}

@media (max-width: 767px) {
  .headerQuestionBox .questionCustomBtn {
    display: none;
  }

  .mobileTopic {
    display: block;
  }

  .theoryMobile {
    display: block;
    max-width: none;
    margin-top: 30px;
  }
}

@media (max-width: 500px) {
  .emptyMarkedQuestionsTitle {
    font-size: 18px;
    font-weight: 700;
  }

  .emptyMarkedQuestionsDescriptionWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .emptyMarkedQuestionsDescriptionWrapper p {
    font-size: 16px;
    margin: 0 0 25px 0;
  }

  .emptyMarkedQuestionsDescriptionWrapper button {
    align-self: center;
  }
}

@media (max-width: 430px) {
  .questionLinkBottom button,
  .questionLinkBottom a {
    padding: 5px 10px;
    font-size: 15px;
  }
}
