.cardItem {
  background-color: var(--whiteColor);
  box-shadow: 7px 7px 20px var(--box-shadow);
  border-radius: 6px;
  max-width: calc(25% - 10px);
  width: 100%;
  padding: 20px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  min-width: 300px;
}

.cardContent {
  display: block;
  width: 100%;
}

.cardContentTitle,
.cardContentText {
  margin-bottom: 10px;
}

.cardContentTitle {
  color: var(--neutral-dark-blue);
}

.cardContentText {
  color: var(--neutral-gray-blue);
}

.cardFooterLink {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;
  font-size: 15px;
  line-height: 18px;
}

@media (max-width: 1200px) {
  .promoCardItem {
    max-width: calc(50% - 10px);
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .promoCardList {
    margin: 0;
  }

  .promoCardItem {
    max-width: none;
  }
}
