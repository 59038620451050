.storeBtnItem {
  max-width: 164px;
  width: 100%;
  height: 48px;
  background-color: var(--blackColor);
  border-radius: 4px;
}

.storeBtnItem:first-child {
  margin-right: 12px;
}
