.offerCardList :global(.card) {
  margin-bottom: 32px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  border: none;
  max-width: calc(33.3% - 15px);
  width: 100%;
  overflow: hidden;
}

.offerAccordionList :global(.card) {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: stretch;
  max-width: none;
}

.card-body {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 25px 20px 0;
  width: 100%;
}

.card-title,
.card-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.card-title {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 28px;
  color: var(--neutral-dark-blue);
}

.header-text {
  font-family: 'Ubuntu', sans-serif;
  line-height: 21px;
  font-size: 14px;
  font-weight: bold;
  color: var(--modal-footer-btn-color);
}

.card-text {
  font-family: 'Hind', sans-serif;
}

.card-footer {
  background-color: transparent;
  border-top: none;
  padding: 0 20px 25px;
  margin-top: 44px;
}

.footerDays {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  line-height: 21px;
  font-size: 14px;
  color: var(--functional-success);
}

.card-text {
  color: var(--neutral-dark-blue-70);
}

.offerCard {
  background-color: var(--neutral-light-gray);
}

.placeholderBox {
  width: calc(33.3% - 15px);
  background-color: var(--whiteColor);
  margin-bottom: 30px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
}

.offerCardList {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.offerCardList :global(.card) {
  margin-bottom: 32px;
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  border: none;
  max-width: calc(33.3% - 15px);
  width: 100%;
  overflow: hidden;
}

.offerCardList :global(.card-body) {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 25px 20px 0;
  width: 100%;
}

.offerCardList :global(.card-title),
.offerCardList :global(.card-footer) {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.offerCardList :global(.card-title) {
  font-family: 'Ubuntu', sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  margin-bottom: 28px;
  color: var(--neutral-dark-blue);
}

.offerCardList :global(.header-text) {
  font-family: 'Ubuntu', sans-serif;
  line-height: 21px;
  font-size: 21px;
  font-weight: bold;
  color: var(--modal-footer-btn-color);
}

.offerCardList :global(.card-text) {
  font-family: 'Hind', sans-serif;
}

.offerCardList :global(.card-footer) {
  background-color: transparent;
  border-top: none;
  padding: 0 20px 25px;
  margin-top: 44px;
}

.offerCardList :global(.footerDays) {
  font-family: 'Ubuntu', sans-serif;
  font-weight: bold;
  line-height: 21px;
  font-size: 21px;
  color: var(--functional-success);
}

.offerCardList :global(.continueText) {
  font-size: 24px;
}

.offerCardList :global(.card-text) {
  color: var(--neutral-dark-blue-70);
  width: 100%;
}

.offerCardList :global(.offerCard) {
  background-color: var(--neutral-light-gray);
}

@media (max-width: 991px) {
  .card,
  .placeholderBox {
    max-width: calc(50% - 15px);
    margin-bottom: 20px;
    background-color: var(--lavender-grey);
  }
}

@media (max-width: 767px) {
  .card {
    max-width: none;
  }

  h3,
  .h3 {
    font-size: 14px;
    line-height: 22px;
  }

  .placeholderBox {
    width: 100%;
    max-width: 100%;
    height: 60px;
    background-color: var(--lavender-grey);
  }

  .offerAccordionList {
    display: flex;
    flex-direction: column;
  }
}
