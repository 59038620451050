.container h1 {
  font-size: 50px;
  text-align: center;
}

.container img {
  padding-top: 40px;
  max-width: 80%;
  display: block;
  margin: 0 auto;
}

.container {
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .container img {
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .container h1 {
    font-size: 40px;
  }
}
