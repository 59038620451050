.multiModal :global(.modal-content) {
  background-color: transparent;
  position: static;
}

.multiModal :global(.modal-header button.close) {
  position: fixed;
  top: 0;
  right: 0;
}

.multiModal :global(.modal-header button.close:before) {
  color: var(--whiteColor);
}

.multiModal :global(.modal-body) {
  padding: 0;
}

.multiModal :global(.modal-body img) {
  border-radius: 10px;
}

.zoomImg {
  width: 100%;
  height: 100%;
}

.videoContent {
  width: 100%;
}
