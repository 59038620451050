.container {
  margin-bottom: 40px;
}

.description {
  text-align: center;
  padding-top: 30px;
}

.offerCard {
  box-shadow: 7px 7px 20px rgba(0, 0, 0, 0.11);
  border-radius: 6px;
  background-color: var(--whiteColor);
  max-width: 408px;
  width: 100%;
  margin: 0 auto 0 auto;
}

.offerPageVip {
  background-color: var(--neutral-light-gray);
  padding: 60px 0 40px;
}

.offerCard {
  margin-bottom: 0;
  border: none;
}

.offerCard :global(.card-body) {
  width: 100%;
  padding: 32px 25px;
}

.offerCard :global(.card-body .h5) {
  text-align: center;
}

.offerCard :global(.card-text) {
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.cardFooterSingle {
  background-color: var(--button-primary-enabled);
  padding: 0;
  border-radius: 0 0 10px 10px;
}

.offerFooterLink {
  display: block;
  width: 100%;
  text-align: center;
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: var(--not-changine-black);
  padding: 15px 20px;
  transition: all ease-in 0.3s;
}

.offerFooterLink:hover,
.offerFooterLink:focus {
  background-color: var(--button-primary-focused);
  color: var(--not-changine-black);
}

.offerVipCard {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 991px) {
  .offerCard {
    margin: 0 auto;
  }

  .offersPageText {
    padding: 40px 0 40px;
  }

  .offerPageVip {
    padding: 40px 0 40px;
  }

  .offerPageCard {
    margin-bottom: 24px;
  }

  .offerPageCard :global(.offerCardList .card) {
    max-width: calc(50% - 15px);
    margin-bottom: 20px;
    background-color: var(--lavender-grey);
  }
}

@media (max-width: 767px) {
  .offerPageCard :global(.offerCardList .card) {
    max-width: none;
  }

  .offerPageCard :global(.offerCardList) {
    display: none;
  }

  .offerVipCard {
    display: none;
  }
}
