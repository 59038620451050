.rulesList {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 42px;
}

.rulesListItem {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.rulesListItem:not(:last-child) {
  margin-right: 35px;
}

.rulesListItemBg {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-icon-enabled);
  width: 60px;
  height: 60px;
  padding: 0;
  border-radius: 50%;
  color: var(--theme-primary-80);
  min-width: auto;
  border-width: 0;
  transition: all ease 0.3s;
}

.rulesListItemBg:hover {
  background-color: var(--theme-primary);
  color: var(--whiteColor);
}

.rulesListItemText {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  line-height: 1;
  white-space: nowrap;
  color: var(--text-color);
  margin-top: 8px;
}

.rulesListItemBg.active {
  background-color: var(--theme-primary);
  color: var(--whiteColor);
}

@media (max-width: 1300px) {
  .rulesListItem:not(:last-child) {
    margin-right: 15px;
  }
}
