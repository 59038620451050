.modalLg {
  position: relative;
  max-width: 720px;
}

.modalLg :global .modal-content {
  box-shadow: 7px 7px 7px rgba(0, 0, 0, 0.03);
}

.modalLg :global .modal-body,
.modalLg :global .modal-header {
  padding: 0;
}

.modalLg :global .modal-header button.close {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 200;
}

.modalLg :global .modal-header button.close:before {
  color: var(--whiteColor);
}

.modalLg :global .modal-header button.close > span {
  display: none;
}

.modalContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.modalImg {
  max-width: 100%;
  height: auto;
  border-radius: 0 6px 6px 0;
}

.modalContentText {
  padding: 0 22px 0 36px;
}

.modalLg :global .modal-title {
  margin-bottom: 10px;
}

.modalText {
  font-family: 'Hind', sans-serif;
  font-size: 15px;
  line-height: 24px;
  color: var(--not-changine-black);
  margin-bottom: 26px;
}

@media (max-width: 991px) {
  .modalLg {
    max-width: 350px;
  }

  .modalContent {
    flex-flow: row wrap-reverse;
  }

  .modalContentText {
    padding: 26px 0 0;
  }

  .modalLg :global .modal-title {
    margin: 0 20px 16px;
  }

  .modalText {
    margin: 0 20px 26px;
  }

  .modalImg {
    border-radius: 6px 6px 0 0;
  }

  .modalBtn {
    max-width: none;
    width: 100%;
  }
}
