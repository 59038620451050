.container {
  padding: 40px 0;
  display: flex;
  align-items: center;
}

.bannerContainer {
  display: flex;
  margin-top: 0;
  border-radius: 0.5rem;
  /*-webkit-box-shadow: 0 0 0.625rem -0.125rem #d6dee6;*/
  /*box-shadow: 0 0 0.625rem -0.125rem #d6dee6;*/
  /*background-color: var(--button-default-focused);*/
  padding: 40px 0 20px 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 991px) {
  .container h1 {
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .container h1 {
    text-align: left;
    max-width: 310px;
    margin: 0 auto;
  }
}
