.offersPageText {
  padding-top: 60px;
  padding-bottom: 60px;
}

.mainTitle {
  text-align: center;
}

.pageTextList,
.pageTextListMobile {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}

.pageTextList > p,
.pageTextListMobile > p {
  width: 100%;
}

.list {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list ul {
  display: inline-block;
}

.list li {
  margin: 8px 0;
  display: flex;
  align-items: center;
}

.list svg {
  margin-right: 10px;
}

.listTitle {
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

.pageTextContainer {
  background-color: var(--whiteColor);
}

.pageTextListMobile {
  display: none;
}

.iconsList {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 62px;
  margin-top: 30px;
}

.iconsListItem {
  width: 100px;
  height: 100px;
}

.iconsListItem:not(:last-child) {
  margin-right: 20px;
}

.offerPageCard {
  background-color: var(--neutral-light-gray);
  padding-top: 60px;
  padding-bottom: 60px;
}

.furtherAdvantages {
  background-color: var(--whiteColor);
  padding-top: 60px;
  padding-bottom: 60px;
}

.offerPageCard :global(.card) {
  background-color: var(--whiteColor);
}

.offerPageContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.offerCard {
  background-color: var(--whiteColor);
  max-width: 408px;
  width: 100%;
  margin-top: 40px;
}

.offerPageItem {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  max-width: 50%;
  width: 100%;
}

.offerPageItem :global(.pageSubtitle) {
  text-align: center;
}

.offerPageVip {
  background-color: var(--neutral-light-gray);
  padding: 60px 0 40px;
}

.offerPageVip .offerCard {
  margin-bottom: 0;
  border: none;
}

.offerPageVip .offerCard :global(.card-body) {
  width: 100%;
  padding: 40px 32px;
}

.offerPageVip .offerCard :global(.card-text) {
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.cardFooterSingle {
  background-color: var(--button-primary-enabled);
  padding: 0;
  border-radius: 0 0 10px 10px;
}

.offerFooterLink {
  display: block;
  width: 100%;
  text-align: center;
  font-family: 'Hind', sans-serif;
  font-size: 18px;
  line-height: 22px;
  color: var(--not-changine-black);
  padding: 15px 20px;
  transition: all ease-in 0.3s;
}

.offerFooterLink:hover,
.offerFooterLink:focus {
  background-color: var(--button-primary-focused);
  color: var(--not-changine-black);
}

.offerVipCard {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1199px) {
  .pageTextList > p {
    max-width: none;
  }
}

@media (max-width: 991px) {
  .offerPageItem {
    max-width: none;
    margin-bottom: 24px;
  }

  .offerCard {
    margin: 0 auto;
  }

  .pageTextListMobile {
    display: block;
    text-align: center;
  }

  .pageTextListMobile > p {
    margin: 0 auto 15px;
  }

  .pageTextList {
    display: none;
  }

  .iconsList {
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 24px;
  }

  .offersPageText {
    padding: 40px 0 40px;
  }

  .offerPageVip {
    padding: 40px 0 40px;
  }

  .offerPageCard {
    margin-bottom: 24px;
  }

  .furtherAdvantages {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .offerPageCard :global(.offerCardList .card) {
    max-width: calc(50% - 15px);
    margin-bottom: 20px;
    background-color: var(--lavender-grey);
  }
}

@media (max-width: 767px) {
  .list {
    padding: 0;
  }

  .offerPageCard :global(.offerCardList .card) {
    max-width: none;
  }

  .offerPageCard :global(.offerCardList) {
    display: none;
  }

  h3,
  .h3 {
    font-size: 18px;
    line-height: 22px;
  }

  .offerVipCard {
    display: none;
  }
}
