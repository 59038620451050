.container {
  position: relative;
}

.chartContainer {
  margin-right: 0;
  transform: scale(0.9);
  margin-top: -12px;
}

.chartContainer :global(.recharts-default-legend) {
  text-align: left !important;
}

.percentInCenter {
  position: absolute;
  /*TODO fix in future*/
  left: 52%;
  top: 46%;
  transform: translate(-50%, -50%);
  font-size: 16px;
}

@media (max-width: 1199px) {
  .chartContainer {
    transform: scale(1.2);
    margin-top: -15px;
  }

  .percentInCenter {
    left: 50%;
    top: 50%;
  }
}
