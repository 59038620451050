.topContainer {
  margin-bottom: 80px;
}

.callToActionContainer,
.vipCodeContainer,
.appContainer {
  padding: 90px 0;
  background-color: var(--lavender-grey);
}

.keyBenefitsContainer,
.functionsContainer,
.shopSection {
  padding: 90px 0;
}
