.examInfoCard {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  background-color: var(--lavender-grey);
  border-radius: 6px;
  padding: 24px 24px 8px;
  max-width: 346px;
  width: 100%;
}

.examCardItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: calc(50% - 10px);
  width: 100%;
  margin-bottom: 12px;
}

.cardLabel {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--neutral-gray-blue-50);
  margin-bottom: 4px;
}

.cardValue {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.examPage .rulesList {
  margin: 20px 0 44px;
}
