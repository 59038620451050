.root:global(.modal-sm) {
  max-width: 355px;
  background-color: var(--lavender-grey);
  border-radius: 5px;
}

.modalBody {
  font-family: 'Hind', sans-serif;
  font-weight: 500;
}

.modalBody :global(p) {
  margin: 0;
}

.modalHeader {
  padding: 0;
}

.modalHeader:global(.modal-header button.close:before) {
  color: var(--theme-primary);
}

.modalHeader:global(.modal-header button.close) {
  z-index: 20;
}

.infoSection {
  padding: 20px;
  border-bottom: 1px solid var(--additional-gray-blue);
}

.nestedSection {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.nestedSection > div {
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
  flex-grow: 1;
}

.infoSection:last-child {
  border-bottom: 1px solid transparent;
}

.generalInfoSection {
  display: flex;
}

.generalInfoBox {
  width: 50%;
  word-break: break-word;
}

.imageContainer {
  width: 60%;
  padding-right: 20px;
  min-height: 20px;
}

.titleText {
  font-family: Ubuntu;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  color: var(--neutral-gray-blue);
}

.subTitleText {
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue);
}

.labelText {
  font-size: 14px;
  line-height: 22px;
  color: var(--neutral-gray-blue-50);
}

.infoText {
  font-size: 18px;
  line-height: 26px;
  color: var(--neutral-gray-blue);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.controlsContainer {
  display: flex;
  overflow: hidden;
}

.controlsContainer :global(.btn) {
  width: 100%;
  border-radius: 0;
}
