.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 0.625rem -0.125rem var(--box-shadow);
  margin: 20px 0;
  width: 100%;
}

:global(.accordionGrid) .container {
  max-width: 49%;
}

body :global(.accordionGrid) .isSelected {
  width: 100%;
  max-width: 100%;
}

:global(.dark-theme) .container {
  background-color: var(--neutral-light-gray-no-opacity);
}

.toggleButton {
  border-radius: 15px;
  background-color: #fff;
  width: 100%;
  padding: 0;
  cursor: pointer;
  z-index: 999;
}

.toggleButton:hover {
  cursor: pointer;
}

:global(.dark-theme) .toggleButton {
  background-color: var(--neutral-light-gray-no-opacity);
}

.header {
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  pointer-events: none;
  -webkit-box-shadow: 0 0.125rem 0.5625rem -0.3125rem #d6dee6;
  box-shadow: 0 0.125rem 0.5625rem -0.3125rem #d6dee6;
  color: var(--text-color);
  width: 100%;
}

.headerIcon {
  border-radius: 100%;
  width: 50px;
  height: 50px;
  border: 1px solid var(--theme-primary-80);
  display: flex;
  justify-content: center;
  align-items: center;
}

.headerText {
  padding: 0 20px;
  display: flex;
  font-size: 20px;
  flex: 1;
}

.headerPercent {
  display: flex;
  padding: 0 20px;
  flex: 1;
  max-width: 222px;
  width: 100%;
}

.percentWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.percentWrapper :global(.progress) {
  width: 100%;
  max-width: 120px;
}

.percentWrapper p {
  margin: 0 0 0 10px;
  font-size: 18px;
  white-space: nowrap;
}

.body {
  padding: 15px;
  display: none;
}

.isOpen {
  opacity: 1;
  display: block;
}

.container :global(.collapsing) {
  opacity: 1;
  overflow: hidden;
  transition: all 0s;
}

.status {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 10px;
}

.container .bad :global(.progress-bar) {
  background-color: var(--functional-warning);
}

.container .good :global(.progress-bar) {
  background-color: var(--functional-success);
}

.container .middle :global(.progress-bar) {
  background-color: var(--button-primary-pressed);
}

.container .empty :global(.progress-bar) {
  background-color: var(--additional-gray-blue);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.footer button {
  margin: 0 10px;
}

@media (max-width: 800px) {
  .percentWrapper :global(.progress) {
    width: 100%;
    max-width: 90px;
  }

  .percentWrapper {
    justify-content: flex-end;
  }
}

@media (max-width: 550px) {
  .header {
    padding: 15px 0 15px 15px;
  }

  .headerPercent {
    padding: 0 10px;
    max-width: 222px;
  }

  .headerText {
    padding: 0 0 0 15px;
    font-size: 14px;
    text-align: left;
  }
}

@media (max-width: 450px) {
  .percentWrapper :global(.progress) {
    max-width: unset;
  }
}
